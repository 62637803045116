import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import { store } from './store';
import App from "./App";

// Find the root DOM node where the React app will be mounted
const rootElement = document.getElementById("root");

// Ensure that the root element exists
if (rootElement) {
  // Create a root using React 18's createRoot API
  const appRoot = createRoot(rootElement);

  // Wrap the App component with Redux Provider
  appRoot.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
  );
} else {
  console.error("Failed to find the root element to mount the React app.");
}
