// csrfSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCsrfToken } from "./api";

export const initializeCsrfToken = createAsyncThunk(
  "csrf/initialize",
  async (_, { rejectWithValue }) => {
    try {
      const token = await fetchCsrfToken();
      if (!token) {
        throw new Error('Failed to get CSRF token');
      }
      return token;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const csrfSlice = createSlice({
  name: "csrf",
  initialState: {
    token: null,
    error: null,
    isInitializing: false
  },
  reducers: {
    setCsrfToken: (state, action) => {
      state.token = action.payload;
      state.error = null;
    },
    clearCsrfToken: (state) => {
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeCsrfToken.pending, (state) => {
        state.isInitializing = true;
        state.error = null;
      })
      .addCase(initializeCsrfToken.fulfilled, (state, action) => {
        state.token = action.payload;
        state.error = null;
        state.isInitializing = false;
      })
      .addCase(initializeCsrfToken.rejected, (state, action) => {
        state.error = action.payload;
        state.isInitializing = false;
      });
  },
});

export const { setCsrfToken, clearCsrfToken } = csrfSlice.actions;
export default csrfSlice.reducer;
