import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { sendChatMessage, getTaskStatus, fetchChatSessions, createChatSession, updateChatSessionName } from './api';

// Async thunks
export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async ({ message, sessionId, externalContext, csrfToken }, { rejectWithValue }) => {
    try {
      if (!csrfToken) {
        throw new Error('CSRF token not available');
      }

      let formattedMessage;
      if (typeof message === 'object' && message.type === 'MOVE') {
        formattedMessage = `!MOVE {"new_order": ${JSON.stringify(message.new_order)}}`;
      } else {
        formattedMessage = message;
      }

      console.log("Sending message to API:", {
        message: formattedMessage,
        sessionId,
        externalContext
      });

      const response = await sendChatMessage(formattedMessage, sessionId, externalContext, csrfToken);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const pollTaskStatus = createAsyncThunk(
  'chat/pollTaskStatus',
  async (taskId, { dispatch }) => {
    try {
      const data = await getTaskStatus(taskId);
      if (data?.result?.status === "completed") {
        return data.result.message;
      } else if (data?.result?.status === "failed") {
        throw new Error("Task failed");
      } else {
        // Continue polling
        setTimeout(() => dispatch(pollTaskStatus(taskId)), 1000);
        return null;
      }
    } catch (error) {
      throw error;
    }
  }
);

// Add this action
export const updateSessionMessages = createAction('chat/updateSessionMessages');

// Add new thunks
export const loadSessions = createAsyncThunk(
  'chat/loadSessions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchChatSessions();
      if (!response.success) {
        return rejectWithValue(response.error || 'Failed to load sessions');
      }
      return response.sessions || [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createNewSession = createAsyncThunk(
  'chat/createNewSession',
  async ({ name, csrfToken }, { getState, rejectWithValue }) => {
    try {
      // Check if session limit is reached
      const state = getState();
      if (state.chat.sessions.length >= 5) {
        return rejectWithValue('Maximum limit of 5 active sessions reached. Please delete an existing session first.');
      }
      
      const response = await createChatSession(name, csrfToken);
      if (!response.success) {
        return rejectWithValue(response.error || 'Failed to create session');
      }
      return response.session;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const loadSessionPlaylist = createAsyncThunk(
  'chat/loadSessionPlaylist',
  async ({ sessionId, csrfToken }, { dispatch }) => {
    try {
      const response = await sendChatMessage('!LIST', sessionId, [], csrfToken);
      return response;
    } catch (error) {
      console.error('Error loading session playlist:', error);
      throw error;
    }
  }
);

export const updateSessionName = createAsyncThunk(
  'chat/updateSessionName',
  async ({ sessionId, name, csrfToken }, { rejectWithValue }) => {
    try {
      const response = await updateChatSessionName(sessionId, name, csrfToken);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  sessions: [],
  currentSessionId: null,
  isProcessing: false,
  currentPlaylist: null,
  processingPlaylist: null,
  selectedCommand: null,
  useContext: false,
  error: null,
  commandState: 'INITIAL',
  searchMode: 'title', // 'title', 'director', 'cast', 'writer'
  searchQuery: '',
  filterField: '',
  filterOrder: 'asc',
  filteredPlaylist: null,
  genreFilter: '',
  suggestions: null,
  tokenBalance: null,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setCurrentSession: (state, action) => {
      state.currentSessionId = action.payload;
    },
    addMessage: (state, action) => {
      const currentSession = state.sessions.find(s => s.id === state.currentSessionId);
      if (currentSession) {
        currentSession.messages.push(action.payload);
      }
    },
    setSelectedCommand: (state, action) => {
      state.selectedCommand = action.payload;
    },
    setUseContext: (state, action) => {
      state.useContext = action.payload;
    },
    setCommandState: (state, action) => {
      state.commandState = action.payload;
    },
    setCurrentPlaylist: (state, action) => {
      state.currentPlaylist = action.payload;
    },
    setProcessingPlaylist: (state, action) => {
      state.processingPlaylist = action.payload;
    },
    [updateSessionMessages]: (state, action) => {
      const { sessionIndex, messages } = action.payload;
      state.sessions[sessionIndex].messages = messages;
    },
    updatePlaylist: (state, action) => {
      const { sessionIndex, playlist } = action.payload;
      if (state.sessions[sessionIndex]) {
        const lastMessageIndex = state.sessions[sessionIndex].messages.length - 1;
        if (lastMessageIndex >= 0) {
          state.sessions[sessionIndex].messages[lastMessageIndex].data.playlist = playlist;
        }
      }
    },
    setSearchMode: (state, action) => {
      state.searchMode = action.payload;
      // Only reset filtered results if there's an active search
      if (state.searchQuery) {
        // Re-apply the current search with the new mode
        const playlist = state.currentPlaylist || state.processingPlaylist;
        if (playlist) {
          const query = state.searchQuery.toLowerCase();
          
          const filtered = {
            ...playlist,
            movies: [...playlist.movies].filter(movie => {
              const originalIndex = playlist.movies.findIndex(m => m.id === movie.id);
              movie.originalIndex = originalIndex;
              
              switch (action.payload) { // Use the new search mode
                case 'title':
                  return movie.name?.toLowerCase().includes(query);
                case 'director':
                  return Array.isArray(movie.director) ? 
                    movie.director.some(d => d?.toLowerCase().includes(query)) :
                    movie.director?.toLowerCase().includes(query);
                case 'cast':
                  return Array.isArray(movie.cast) ? 
                    movie.cast.some(c => c?.toLowerCase().includes(query)) :
                    movie.cast?.toLowerCase().includes(query);
                case 'writer':
                  return Array.isArray(movie.writer) ? 
                    movie.writer.some(w => w?.toLowerCase().includes(query)) :
                    movie.writer?.toLowerCase().includes(query);
                default:
                  return true;
              }
            })
          };
          state.filteredPlaylist = { ...filtered };
        }
      }
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
      if (state.currentPlaylist || state.processingPlaylist) {
        const playlist = state.currentPlaylist || state.processingPlaylist;
        if (!action.payload) {
          state.filteredPlaylist = null;
          return;
        }
        
        const query = action.payload.toLowerCase();
        
        const filtered = {
          ...playlist,
          movies: [...playlist.movies].filter(movie => {
            // Keep track of original indices
            const originalIndex = playlist.movies.findIndex(m => m.id === movie.id);
            movie.originalIndex = originalIndex;
            
            switch (state.searchMode) {
              case 'title':
                return movie.name?.toLowerCase().includes(query);
              case 'director':
                return Array.isArray(movie.director) ? 
                  movie.director.some(d => d?.toLowerCase().includes(query)) :
                  movie.director?.toLowerCase().includes(query);
              case 'cast':
                return Array.isArray(movie.cast) ? 
                  movie.cast.some(c => c?.toLowerCase().includes(query)) :
                  movie.cast?.toLowerCase().includes(query);
              case 'writer':
                return Array.isArray(movie.writer) ? 
                  movie.writer.some(w => w?.toLowerCase().includes(query)) :
                  movie.writer?.toLowerCase().includes(query);
              default:
                return true;
            }
          })
        };
        state.filteredPlaylist = { ...filtered };
      }
    },
    setFilterField: (state, action) => {
      state.filterField = action.payload;
      if (state.currentPlaylist || state.processingPlaylist) {
        const playlist = state.filteredPlaylist || state.currentPlaylist || state.processingPlaylist;
        const sorted = {
          ...playlist,
          movies: [...playlist.movies].sort((a, b) => {
            let aVal = a[action.payload];
            let bVal = b[action.payload];
            
            // Handle special cases
            if (action.payload === 'released') {
              aVal = new Date(aVal || 0);
              bVal = new Date(bVal || 0);
            } else if (action.payload === 'imdbRating' || action.payload === 'reason_score') {
              aVal = parseFloat(aVal) || 0;
              bVal = parseFloat(bVal) || 0;
            }
            
            const order = state.filterOrder === 'asc' ? 1 : -1;
            
            if (aVal instanceof Date) {
              return order * (aVal - bVal);
            }
            if (typeof aVal === 'string') {
              return order * aVal.localeCompare(bVal);
            }
            return order * (aVal - bVal);
          })
        };
        state.filteredPlaylist = { ...sorted };
      }
    },
    setFilterOrder: (state, action) => {
      state.filterOrder = action.payload;
      if (state.filterField) {
        const playlist = state.filteredPlaylist || state.currentPlaylist || state.processingPlaylist;
        const sorted = {
          ...playlist,
          movies: [...playlist.movies].sort((a, b) => {
            const aVal = a[state.filterField];
            const bVal = b[state.filterField];
            const order = action.payload === 'asc' ? 1 : -1;
            
            if (typeof aVal === 'string') {
              return order * aVal.localeCompare(bVal);
            }
            return order * (aVal - bVal);
          })
        };
        state.filteredPlaylist = sorted;
      }
    },
    setGenreFilter: (state, action) => {
      state.genreFilter = action.payload;
      if (state.currentPlaylist || state.processingPlaylist) {
        const playlist = state.filteredPlaylist || state.currentPlaylist || state.processingPlaylist;
        if (!action.payload || action.payload === 'All Genres') {
          // If no genre filter, maintain other filters
          if (state.searchQuery || state.filterField) {
            state.filteredPlaylist = playlist;
          } else {
            state.filteredPlaylist = null;
          }
          return;
        }
        
        const filtered = {
          ...playlist,
          movies: playlist.movies.filter(movie => {
            const movieGenres = Array.isArray(movie.genre) ? movie.genre : 
                              Array.isArray(movie.genres) ? movie.genres : 
                              [movie.genre || movie.genres];
            return movieGenres.some(g => g?.toLowerCase() === action.payload.toLowerCase());
          })
        };
        state.filteredPlaylist = filtered;
      }
    },
    setSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
    setTokenBalance: (state, action) => {
      state.tokenBalance = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.isProcessing = true;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        if (action.payload?.task_id) {
          state.currentTaskId = action.payload.task_id;
        }
        if (action.payload?.tokens_remaining !== undefined) {
          state.tokenBalance = action.payload.tokens_remaining;
        }
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isProcessing = false;
        state.error = action.payload;
        state.processingPlaylist = null;
        state.commandState = 'INITIAL';
      })
      .addCase(pollTaskStatus.fulfilled, (state, action) => {
        if (action.payload) {
          const responseData = action.payload;
          
          const currentSession = state.sessions.find(s => s.id === state.currentSessionId);
          if (currentSession) {
            currentSession.messages.push({
              role: 'assistant',
              content: responseData.message || "No response message",
              data: {
                ...responseData.data,
                suggestions: responseData.suggestions || null
              }
            });

            // Update suggestions in state when received
            if (responseData.suggestions) {
              state.suggestions = responseData.suggestions;
            }
          }
          
          if (responseData.message === "No playlist exists. Create a new one.") {
            state.currentPlaylist = null;
            state.processingPlaylist = null;
            state.commandState = 'INITIAL';
            // Set default suggestions when no playlist exists
            state.suggestions = {
              add_suggestions: [
                "Find critically acclaimed movies from the past decade",
                "Discover hidden gems from independent filmmakers",
                "Show me award-winning international films"
              ],
              analysis_suggestions: [
                "Can you analyze the genre distribution in my playlist?",
                "What patterns do you see in the directors and their movies?", 
                "How do the ratings compare across different decades?"
              ],
              exploration_suggestions: [
                "Show me how the movie lengths vary across genres",
                "What connections exist between directors and cast members?",
                "How does the geographic diversity affect the themes?"
              ]
            };
          } else if (responseData.data?.playlist) {
            if (responseData.message === "Found these movies!" || 
                responseData.message === "✨ Current playlist loaded:") {
              if (responseData.message === "Found these movies!") {
                state.processingPlaylist = responseData.data.playlist;
                state.commandState = 'SELECTION';
              } else {
                state.currentPlaylist = responseData.data.playlist;
                state.commandState = 'EDITING';
                state.processingPlaylist = null;
              }
            } else if (responseData.message === "Movies added to playlist.") {
              state.currentPlaylist = responseData.data.playlist;
              state.processingPlaylist = null;
              state.commandState = 'INITIAL';
            } else if (responseData.message.includes('reordered') || 
                      responseData.message.includes('removed')) {
              state.currentPlaylist = responseData.data.playlist;
            } else if (responseData.message === "Selection cancelled.") {
              state.processingPlaylist = null;
              state.currentPlaylist = responseData.data.playlist;
              state.commandState = 'INITIAL';
            } else {
              state.currentPlaylist = responseData.data.playlist;
              state.commandState = 'INITIAL';
              state.processingPlaylist = null;
            }
          } else if (responseData.message === "Selection cancelled.") {
            state.processingPlaylist = null;
            state.commandState = 'INITIAL';
          }
          
          state.isProcessing = false;
          state.error = null;
        }
      })
      .addCase(pollTaskStatus.rejected, (state, action) => {
        state.isProcessing = false;
        state.error = action.error.message;
        state.processingPlaylist = null;
        state.commandState = 'INITIAL';
        state.selectedCommand = null;
      })
      // Add cases for loading sessions
      .addCase(loadSessions.fulfilled, (state, action) => {
        // Filter out expired sessions
        const now = new Date();
        state.sessions = action.payload
          .filter(session => new Date(session.expires_at) > now)
          .map(session => ({
            id: session.id,
            name: session.name || `Chat ${session.id.slice(0, 8)}`,
            messages: [],
            expires_at: session.expires_at
          }));
        state.currentSessionId = null;
      })
      .addCase(loadSessions.rejected, (state, action) => {
        state.sessions = [];
        state.error = action.payload || 'Failed to load sessions';
      })
      // Add cases for loading session playlist
      .addCase(loadSessionPlaylist.fulfilled, (state, action) => {
        if (action.payload?.data?.current_playlist) {
          state.currentPlaylist = action.payload.data.current_playlist;
          state.commandState = 'EDITING';
        }
      })
      // Add cases for creating new session
      .addCase(createNewSession.fulfilled, (state, action) => {
        state.sessions.push({
          id: action.payload.session_id,
          name: action.payload.name,
          messages: [],
          expires_at: action.payload.expires_at
        });
        state.currentSessionId = action.payload.session_id;
        state.commandState = 'INITIAL';
        state.currentPlaylist = null;
      })
      .addCase(updateSessionName.fulfilled, (state, action) => {
        const session = state.sessions.find(s => s.id === action.payload.session_id);
        if (session) {
          session.name = action.payload.name;
        }
      });
  }
});

export const {
  setCurrentSession,
  addMessage,
  setSelectedCommand,
  setUseContext,
  setCommandState,
  setCurrentPlaylist,
  setProcessingPlaylist,
  setSearchMode,
  setSearchQuery,
  setFilterField,
  setFilterOrder,
  setGenreFilter,
  setSuggestions,
  setTokenBalance,
} = chatSlice.actions;

export default chatSlice.reducer; 