import axios from "axios";

// Enable cookies globally for all Axios calls
axios.defaults.withCredentials = true;

// Add a request interceptor
axios.interceptors.request.use((config) => {
  config.headers.Accept = "application/json";
  // Add more headers or modify the config here
  return config;
});

// Add a response interceptor
axios.interceptors.response.use((response) => {
  // Handle response data here
  return response;
});

const API_URL = "/api"; // Replace with your Flask API URL

const handleAxiosError = (error) => {
  // Create a Bootstrap alert element
  const alert = document.createElement("div");
  alert.className = "alert alert-danger alert_default";
  alert.role = "alert";

  let errorMessage = "Error: ";

  if (error.response) {
    // Handle other kinds of errors
    let detailedMessage =
      error.response.data?.error ||
      error.response.statusText ||
      "Unknown error";
    errorMessage += `${detailedMessage}`;
  } else if (error.request) {
    errorMessage += " The request was made but no response was received.";
  } else {
    errorMessage += " There was a problem setting up the request.";
  }

  alert.textContent = errorMessage;
  document.body.appendChild(alert);

  setTimeout(() => {
    if (alert.parentNode) {
      alert.parentNode.removeChild(alert);
    }
  }, 2000);
};

const handleAxiosSuccess = (response) => {
  // Create a Bootstrap alert element
  const alert = document.createElement("div");
  alert.className = "alert alert-success alert_default"; // Use 'alert-success' for success messages
  alert.role = "alert";

  // Initialize a default success message
  let successMessage = "Success: ";

  if (response && response.data && response.data.message) {
    // If there's a message field in the response, add it to the message
    successMessage += response.data.message;
  } else {
    // Provide a generic success message if none is provided in the response
    successMessage += "The operation was completed successfully.";
  }

  // Set the success message as HTML and append the alert to the body or a specific container
  alert.innerHTML = successMessage;
  document.body.appendChild(alert); // Append to body or a specific error container

  // Optionally remove the alert after some time
  setTimeout(() => {
    if (alert.parentNode) {
      alert.parentNode.removeChild(alert);
    }
  }, 2000); // Remove after 4 seconds
};

export const initiateGoogleLogin = async () => {
  window.location.href = `${API_URL}/login`;
  // return response.data;
};

export const initiateGoogleLogout = async () => {
  window.location.href = `${API_URL}/logout`;
  // return response.data;
};

export const fetchCsrfToken = async () => {
  try {
    const response = await axios.get(`${API_URL}/csrf-token`);
    if (!response.data?.csrf_token) {
      throw new Error('No CSRF token in response');
    }
    return response.data.csrf_token;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    throw new Error('Failed to fetch CSRF token');
  }
};

export const updateMembershipTiers = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_membership_tiers`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const fetchPlaylists = async () => {
  try {
    const response = await axios.get(`${API_URL}/get_playlists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const fetchWatchlist = async () => {
  try {
    const response = await axios.get(`${API_URL}/get_watchlist`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const fetchFavorites = async () => {
  try {
    const response = await axios.get(`${API_URL}/get_favorites`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const fetchRatings = async () => {
  try {
    const response = await axios.get(`${API_URL}/get_ratings`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const fetchRec = async () => {
  try {
    const response = await axios.get(`${API_URL}/get_recommendations`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importImdbList = async (imdb_urls, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/import_imdb_list`,
      {
        imdb_urls: imdb_urls,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importImdbWatchlist = async (imdb_urls, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/import_imdb_watchlist`,
      {
        imdb_urls: imdb_urls,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const syncAllImdbWatchlist = async () => {
  try {
    const response = await axios.get(`${API_URL}/sync_imdb_watchlist`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importImdbRatings = async (formData, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/import_imdb_ratings`,
      formData,
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const syncAllImdbPlaylists = async () => {
  try {
    const response = await axios.get(`${API_URL}/sync_all_imdb_playlists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importTraktWatchlist = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_trakt_watchlist`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};
export const syncAllTraktWatchlist = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_trakt_watchlist`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importTraktFavorites = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_trakt_favorites`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};
export const syncAllTraktFavorites = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_trakt_favorites`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importTraktRatings = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_trakt_ratings`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const syncAllTraktRatings = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_trakt_ratings`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importTraktRec = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_trakt_recommendations`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const syncAllTraktRec = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_trakt_recommendations`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importTmdbRec = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_tmdb_recommendations`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const syncAllTmdbRec = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_tmdb_recommendations`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importTmbdWatchlist = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_tmdb_watchlist`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};
export const syncAllTmdbWatchlist = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_tmdb_watchlist`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importTmbdFavorites = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_tmdb_favorites`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};
export const syncAllTmdbFavorites = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_tmdb_favorites`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importTmbdRatings = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_tmdb_ratings`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};
export const syncAllTmdbRatings = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_tmdb_ratings`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importLetterboxdList = async (listUrl, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/letterboxd/import_list`,
      { list_url: listUrl },
      {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importLetterboxdWatchlist = async (username, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/letterboxd/import_watchlist`,
      { username },
      {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const updateLetterboxdLists = async () => {
  try {
    const response = await axios.get(`${API_URL}/letterboxd/update_lists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const updateLetterboxdWatchlist = async () => {
  try {
    const response = await axios.get(`${API_URL}/letterboxd/update_watchlist`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const fetchSinglePlaylist = async (
  playlistId,
  genre,
  type,
  source,
  sortAsc,
  sortDesc,
  search,
  page,
  randomize = false
) => {
  try {
    const response = await axios.get(
      `${API_URL}/get_single_playlist/${playlistId}`,
      {
        params: {
          genre,
          type,
          source,
          sort_asc: sortAsc,
          sort_desc: sortDesc,
          search,
          page,
          randomize,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const addToPlaylist = async (
  playlistId,
  contentId,
  contentType,
  csrfToken
) => {
  try {
    const response = await axios.post(
      `${API_URL}/add_to_playlist/${playlistId}`,
      {
        id: contentId,
        type: contentType,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const deleteFromPlaylist = async (playlistId, contentId, csrfToken) => {
  try {
    const response = await axios.delete(
      `${API_URL}/delete_from_playlist/${playlistId}`,
      {
        headers: {
          "X-CSRFToken": csrfToken, // Include CSRF token in the request headers
        },
        data: {
          id: contentId, // Data being sent in the body of the delete request
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const reorderPlaylistItem = async (playlistId, newOrder, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/reorder_playlist_items/${playlistId}`,
      {
        new_order: newOrder,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const addNewPlaylist = async (playlistName, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/add_playlist`,
      {
        playlist_name: playlistName,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const deletePlaylist = async (playlistId, csrfToken) => {
  try {
    const response = await axios.delete(
      `${API_URL}/delete_playlist/${playlistId}`,
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const reorderPlaylists = async (newOrder, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/reorder_playlists`,
      {
        new_order: newOrder,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const clonePlaylist = async (
  playlistId,
  new_playlist_name,
  csrfToken
) => {
  try {
    const response = await axios.post(
      `${API_URL}/playlist/${playlistId}/clone`,
      {
        new_playlist_name: new_playlist_name,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const setPrivacyPlaylist = async (playlistId, isPublic, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/playlist/${playlistId}/set_privacy`,
      {
        is_public: isPublic,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const followPlaylist = async (playlistId, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/playlist/${playlistId}/follow`,
      {}, // Empty body, since we're not sending any data with the POST request
      {
        headers: {
          "X-CSRFToken": csrfToken, // Correctly set the CSRF token in the headers
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const unfollowPlaylist = async (playlistId, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/playlist/${playlistId}/unfollow`,
      {}, // Empty body, since we're not sending any data with the POST request
      {
        headers: {
          "X-CSRFToken": csrfToken, // Correctly set the CSRF token in the headers
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const reorderFollowedPlaylists = async (newOrder, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/reorder_followed_playlists`,
      {
        new_order: newOrder,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const fetchPublicPlaylists = async (
  page,
  searchQuery,
  sortFollowers,
  sortItems,
  isAdmin
) => {
  try {
    const response = await axios.get(`${API_URL}/get_public_playlists`, {
      params: {
        page,
        search: searchQuery,
        sort_by_followers: sortFollowers, // Sort parameter for followers
        sort_by_items: sortItems, // Sort parameter for items
        is_admin: isAdmin,
      },
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const traktLogin = async () => {
  window.location.href = `${API_URL}/trakt/login`;
};

export const traktAuth = async (code) => {
  try {
    return await axios.get(`${API_URL}/trakt/authorized?code=${code}`);
  } catch (error) {
    console.error(error);
  }
};

export const importTraktLists = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_trakt_lists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const tmdbLogin = async () => {
  window.location.href = `${API_URL}/tmdb/login`;
};

export const tmdbAuth = async (requestToken) => {
  try {
    return await axios.get(
      `${API_URL}/tmdb/approved?request_token=${requestToken}`
    );
  } catch (error) {
    console.error(error);
  }
};

export const tmdbAuthV4 = async (requestToken) => {
  try {
    return await axios.get(`${API_URL}/tmdb/approved/v4`);
  } catch (error) {
    console.error(error);
  }
};

export const importTmdbLists = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_tmdb_lists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const updateTraktPlaylists = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_trakt_playlists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const updatePublicTraktPlaylists = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/update_public_trakt_playlists`
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const updateTmdbPlaylists = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_tmdb_playlists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const showTraktLists = async () => {
  try {
    const response = await axios.get(`${API_URL}/show_trakt_lists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const trendingTraktLists = async () => {
  try {
    const response = await axios.get(`${API_URL}/trending_trakt_lists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const popularTraktLists = async () => {
  try {
    const response = await axios.get(`${API_URL}/popular_trakt_lists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const searchTraktLists = async (query) => {
  try {
    const response = await axios.get(`${API_URL}/search_trakt_lists`, {
      params: { query },
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importSingleTraktList = async (
  traktId,
  playlistName,
  csrfToken
) => {
  try {
    const response = await axios.post(
      `${API_URL}/import_single_trakt_list`,
      {
        trakt_id: traktId,
        playlist_name: playlistName,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importPublicTraktList = async (
  traktId,
  playlistName,
  csrfToken
) => {
  try {
    const response = await axios.post(
      `${API_URL}/import_public_trakt_list`,
      {
        trakt_id: traktId,
        playlist_name: playlistName,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const showTmdbLists = async () => {
  try {
    const response = await axios.get(`${API_URL}/show_tmdb_lists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importSingleTmdbList = async (
  tmdb_id,
  playlistName,
  csrfToken
) => {
  try {
    const response = await axios.post(
      `${API_URL}/import_single_tmdb_list`,
      {
        tmdb_id: tmdb_id,
        playlist_name: playlistName,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const fetchCustomMetadata = async (movieIds, seriesIds, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/fetch_metadata`,
      {
        movieIds,
        seriesIds,
      },
      {
        headers: {
          // Include CSRF token in the request headers
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const getUserData = async () => {
  try {
    const response = await axios.get(`${API_URL}/get_user_data`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const getTaskStatus = async (task_id) => {
  try {
    const response = await axios.get(`${API_URL}/tasks/${task_id}`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const goToDocs = async () => {
  window.location.href = `https://docs.stremiocustomlists.com/`;
};

export const storeMdblistKey = async (mdblistKey, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/store_mdblist_key`,
      { mdblist_key: mdblistKey },
      {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const addMdblistPlaylist = async (listId, playlistName, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/mdblist/add_playlist`,
      { list_id: listId, playlist_name: playlistName },
      {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const addPublicMdblistPlaylist = async (
  listId,
  playlistName,
  csrfToken
) => {
  try {
    const response = await axios.post(
      `${API_URL}/mdblist/add_public_playlist`,
      { list_id: listId, playlist_name: playlistName },
      {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const updateMdblistPlaylists = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_mdblist_playlists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const updatePublicMdblistPlaylists = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/update_public_mdblist_playlists`
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const searchMdblistLists = async (query) => {
  try {
    const response = await axios.get(`${API_URL}/mdblist/search_lists`, {
      params: { query },
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const getMyMdblistLists = async () => {
  try {
    const response = await axios.get(`${API_URL}/mdblist/my_lists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const getTopMdblistLists = async () => {
  try {
    const response = await axios.get(`${API_URL}/mdblist/top_lists`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const getMdblistLimits = async () => {
  try {
    const response = await axios.get(`${API_URL}/mdblist/my_limits`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const deleteMdblistKey = async (csrfToken) => {
  try {
    const response = await axios.delete(`${API_URL}/mdblist/delete_key`, {
      headers: {
        "X-CSRFToken": csrfToken, // Include CSRF token in the request headers
      },
    });
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const getSyncSettings = async () => {
  try {
    const response = await axios.get(`${API_URL}/sync_settings`);
    return response.data;
  } catch (error) {
    console.error('Error getting sync settings:', error);
    throw error;
  }
};

export const updateSyncSettings = async (settings, csrfToken) => {
  try {
    const response = await axios.post(`${API_URL}/sync_settings`, settings, {
      headers: {
        'X-CSRFToken': csrfToken
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating sync settings:', error);
    throw error;
  }
};

// Add these new chat-related functions
export const sendChatMessage = async (message, sessionId, externalContext = [], csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/rag/chat`,
      {
        message,
        session_id: sessionId,
        external_context: externalContext
      },
      {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
    throw error;
  }
};

// Add the export playlist function
export const exportPlaylist = async (playlistName, movies, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/export_playlist`,
      {
        playlist_name: playlistName,
        movies: movies.map(movie => ({
          id: movie.imdb_id || movie.id,
          type: movie.type
        }))
      },
      {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

// Add this new function
export const getUserPlaylists = async () => {
  try {
    const response = await axios.get(`${API_URL}/rag/playlists`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add these new session management functions
export const fetchChatSessions = async () => {
  try {
    const response = await axios.get(`${API_URL}/chat/sessions`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
    throw error;
  }
};

export const createChatSession = async (name, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/chat/sessions`,
      { name },
      {
        headers: {
          'X-CSRFToken': csrfToken
        }
      }
    );
    return response.data;
  } catch (error) {
    if (error.response?.data?.error) {
      throw new Error(error.response.data.error);
    }
    throw error;
  }
};

export const updateChatSessionName = async (sessionId, name, csrfToken) => {
  try {
    const response = await axios.put(
      `${API_URL}/chat/sessions/${sessionId}/name`,
      { name },
      {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleAxiosError(error);
    throw error;
  }
};

export const getTokenBalance = async () => {
  try {
    const response = await axios.get(`${API_URL}/chat/tokens/balance`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
    throw error;
  }
};

export const claimTokens = async (csrfToken, surveyData = null) => {
  try {
    const payload = surveyData ? { survey: surveyData } : {};
    
    const response = await axios.post(
      `${API_URL}/chat/tokens/faucet`,
      payload,
      {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
    );
    
    if (response.data.success) {
      handleAxiosSuccess(response);
    }
    return response.data;
  } catch (error) {
    handleAxiosError(error);
    throw error;
  }
};



export const simklLogin = async () => {
  window.location.href = `${API_URL}/simkl/login`;
  console.log("Simkl login gz");
};

export const simklAuth = async (code) => {
  try {
    return await axios.get(`${API_URL}/simkl/authorized?code=${code}`);
  } catch (error) {
    console.error(error);
  }
};
export const importSimklWatchlist = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_simkl_watchlist`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};
export const syncAllSimklWatchlist = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_simkl_watchlist`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const importSimklRatings = async () => {
  try {
    const response = await axios.get(`${API_URL}/import_simkl_ratings`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const syncAllSimklRatings = async () => {
  try {
    const response = await axios.get(`${API_URL}/update_simkl_ratings`);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

export const renamePlaylist = async (playlistId, newName, csrfToken) => {
  try {
    const response = await axios.post(
      `${API_URL}/rename_playlist/${playlistId}`,
      {
        new_name: newName
      },
      {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
    );
    handleAxiosSuccess(response);
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};
