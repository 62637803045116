// store.js
import { configureStore } from "@reduxjs/toolkit";
import playlistReducer from "./playlistSlice";
import csrfReducer from "./csrfSlice";
import chatReducer from "./chatSlice";

export const store = configureStore({
  reducer: {
    playlist: playlistReducer,
    csrf: csrfReducer,
    chat: chatReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['chat/sendMessage/fulfilled', 'chat/pollTaskStatus/fulfilled'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.data', 'meta.arg'],
        // Ignore these paths in the state
        ignoredPaths: ['chat.sessions'],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
